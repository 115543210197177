import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export * from './util.currency';
export * from './util.date';
export * from './util.payment';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export async function sleep(seconds: number) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(null), seconds * 1000);
    });
}
